@import "variables";
@import "fullscreen";

body {
  font-family: $font-family-sans-serif;
}

.fullscreen-centered {
  .content {
    .title {
      color: $brand-primary;
    }

    a {
      color: $brand-primary;
      text-decoration: none;

      &:hover {
        color: lighten($brand-primary, 10%);
      }
    }
  }
}
