@import "normalize";

html.fullscreen {
  height: 100%;

  body {
    height: 100%;
    min-height: 100%;

    .fullscreen-centered {
      height: 100%;

      .content {
        width: 500px;
        max-width: 100%;
        position: absolute;
        padding: 20px;
        text-align: center;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .title {
        font-size: 5em;
      }

      .heading {
        font-weight: bold;
      }
    }
  }
}





