$text-color: #333;
$text-color-new: rgba(118, 142, 159, 1);

$brand-primary: #3775d0;
$brand-primary-medium: rgba(48, 173, 180, 1);
$brand-secondary: #35aeef;
$brand-background: #222222;
$brand-info: #8eb4cb;
$brand-medium: #768e95;
$brand-success: #33be60;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
$header-line-color: #d3d3d3;



/////

$short-margin: 45px;
$paper: #f9f9f9;
$black-color: #000000;
$light-grey: #c2c2c2;
$success: #3f9b45;
$dang: #ff4800;
$warn: #d96b00;
$wet-grey: #7ca6b0;
$gold: #ffa900;
$def-font-size: 16px;
$prim: #3775d0;
$purple: #80008e;
$orange: #ff9900;
$link_hover: #6ca3f5;

/////
$default-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.5);

$default-border-color: #a9a9a9;
$default-grey-text-color: #a9a9a9;
$default-placeholder-color: #858585;
$default-background-color: #f6f7f9;

$navbar-height-accented: 100px;
$navbar-height: 100px;

$footer-height: 250px;
$footer-height-mobile: 530px;

$icon-font-path: "/fonts/Glyphicons/";
$font-family-sans-serif:  "Roboto", sans-serif !default;

$panel-footer-height: 220px;


$mess_bg_success: #c7efc9;
$mess_bg_dang: #ffe7de;

/////
$line-over-logo: 2px solid $brand-primary;